import persons from ".data/contentful/person/all.json";

// Custom role order for sorting (CEO first, COO second)
const roleOrder: { [key: string]: number } = {
  CEO: 1,
  COO: 2,
};

// Filter out employees and remove duplicates based on sys.id
const employees = persons
  .filter((person) => person.fields.isEmployee)
  .filter(
    (person, index, self) =>
      index === self.findIndex((p) => p.sys.id === person.sys.id),
  );
// Sort the employees by their role
const sortedEmployees = employees.sort((a, b) => {
  const roleA = a.fields.companyRole;
  const roleB = b.fields.companyRole;

  // Make sure roleA and roleB are always strings
  const orderA = roleOrder[roleA as keyof typeof roleOrder] ?? 3;
  const orderB = roleOrder[roleB as keyof typeof roleOrder] ?? 3;

  return orderA - orderB; // Sort based on the role priority
});

// Map the sorted employees into the desired structure
const employeeFields = sortedEmployees.map((employee) => ({
  name: `${employee.fields.firstName} ${employee.fields.lastName}`,
  role: employee.fields.companyRole,
  image: employee.fields.profilePicture,
}));

export default employeeFields;
