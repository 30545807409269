import refCases from ".data/contentful/refCase/all.json";

const cases = refCases.filter(
  (person, index, self) =>
    index === self.findIndex((p) => p.sys.id === person.sys.id)
);

const caseFields = cases.map((employee) => {
  return {
    title: employee.fields.title,
    description: employee.fields.description,
    customer: employee.fields.customer,
    image: employee.fields.image,
    tags: employee.fields.tags,
    fields: employee.fields,
    sys: employee.sys
  };
});

export default caseFields;
