import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCube, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/autoplay';
import { ImageLoaderProps } from 'next/image';
import { ContentfulImage } from '../../components/Contentful';

import employeesData from 'utils/getEmployees';

const employees = employeesData.map((employee) => {
    return {
        url: employee.image,
        name: employee.name,
        role: employee.role,
    };
});

const myLoader = ({ src }: ImageLoaderProps) => src;

// Assign the component to a variable
const SwiperComponent = () => {
    return (
        <div className="flex-inline mobile:max-w-[80vw] md:max-w-[35vw] sm:max-h-screen justify-center items-center">
            <Swiper
                modules={[Pagination, EffectCube, Autoplay]}
                effect="cube"
                loop={true}
                autoplay={{
                    delay: 5000, // 3 seconds between slides
                    pauseOnMouseEnter: true, // Pause autoplay on hover
                    disableOnInteraction: false, // Continue autoplay after user interaction
                }}
                pagination={{
                    clickable: true,
                    el: '.custom-pagination',
                    renderBullet: (index: number, className: string) =>
                        `<span class="${className} w-2 h-2 rounded-full inline-block mx-1"></span>`,
                }}
                spaceBetween={50}
                slidesPerView={1}
                cubeEffect={{
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                }} // Optional cube effect customization
            >
                {employees.map((image: any, index: number) => (
                    <SwiperSlide key={index}>
                        {/* TODO: contentful image */}
                        <div className="flex">
                            <ContentfulImage
                                alt=""
                                className="flex object-cover m-auto w-fit"
                                width={4000}
                                height={4000}
                                image={image.url}
                                loader={myLoader}
                            />
                        </div>

                        {/* <Image
                            alt=""
                            className="m-auto w-[432px] h-[432px] border border-gray-300 shadow-2xl rounded-lg"
                            width={432}
                            height={432}
                            loader={myLoader}
                            src="/images/persons/person.png"
                        /> */}
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 w-[85%] p-1 backdrop-blur bg-gray-700/25 flex items-center  justify-center flex-col">
                            <p className="mobile:text-[4vw] sm:text-[3vw] md:text-[1.2vw] font-semibold text-white">{image.name}</p>
                            <p className="mobile:text-[4vw] sm:text-[3vw] md:text-[1.2vw] font-semibold text-custom-button-2">{image.role}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Pagination container */}
            <div className="custom-pagination justify-center flex py-2 text-custom-button-2"></div>
        </div>
    );
};

// Export the component as default
export default SwiperComponent;