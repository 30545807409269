//
export { default as lambda } from "../public/images/aws-icons/lambda.png";
export { default as architecture } from "../public/images/aws-icons/architecture.png";
export { default as bedrock } from "../public/images/aws-icons/bedrock.png";
export { default as fargate } from "../public/images/aws-icons/fargate.png";
export { default as a1 } from "../public/images/aws-icons/1.png";
export { default as a2 } from "../public/images/aws-icons/2.png";
export { default as a3 } from "../public/images/aws-icons/3.png";
export { default as a4 } from "../public/images/aws-icons/4.png";
export { default as a5 } from "../public/images/aws-icons/5.png";
export { default as a6 } from "../public/images/aws-icons/6.png";
export { default as a7 } from "../public/images/aws-icons/7.png";
export { default as a8 } from "../public/images/aws-icons/8.png";
export { default as a9 } from "../public/images/aws-icons/9.png";
export { default as a10 } from "../public/images/aws-icons/10.png";
export { default as a11 } from "../public/images/aws-icons/11.png";
export { default as a12 } from "../public/images/aws-icons/12.png";
export { default as a13 } from "../public/images/aws-icons/13.png";
export { default as a14 } from "../public/images/aws-icons/14.png";
export { default as a15 } from "../public/images/aws-icons/15.png";
export { default as a16 } from "../public/images/aws-icons/16.png";
export { default as a17 } from "../public/images/aws-icons/17.png";
export { default as a18 } from "../public/images/aws-icons/18.png";
export { default as a19 } from "../public/images/aws-icons/19.png";
export { default as a20 } from "../public/images/aws-icons/20.png";
export { default as a21 } from "../public/images/aws-icons/21.png";
export { default as a22 } from "../public/images/aws-icons/22.png";
export { default as a23 } from "../public/images/aws-icons/23.png";
export { default as a24 } from "../public/images/aws-icons/24.png";

export { default as lock } from "../public/images/aws-icons/lock.svg";

export { default as left1 } from "../public/images/aws-icons/left-1.svg";
export { default as left2 } from "../public/images/aws-icons/left-2.svg";
export { default as left3 } from "../public/images/aws-icons/left-3.svg";

export { default as right1 } from "../public/images/aws-icons/right-1.svg";
export { default as right2 } from "../public/images/aws-icons/right-2.svg";
export { default as right3 } from "../public/images/aws-icons/right-3.svg";

export { default as vector } from "../public/images/aws-icons/Vector.png";
export { default as titleCloud } from "../public/images/aws-icons/titleCloud.svg";
export { default as titleLock } from "../public/images/aws-icons/titleLock.svg";
export { default as titleAWSLogo } from "../public/images/aws-logo.svg";
