import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import Button from '@components/Button';
import { ContentfulImage } from '@components/Contentful';
import refCasesData from 'utils/getRefCases';
import { FaArrowRight } from 'react-icons/fa';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// Import the Swiper type
import type { Swiper as SwiperType } from 'swiper';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const CustomerCard = () => {
    const prevRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);
    const paginationRef = useRef<HTMLDivElement | null>(null);
    const maxDescriptionLength = 500;
    const [activeIndex, setActiveIndex] = useState(0);

    const truncateText = (text: string, length: number) => {
        return text.length > length ? (
            <div>
                <span>{text.slice(0, length)}</span>
                <span className="text-custom-button"> ...</span>
            </div>
        ) : (
            text
        );
    };

    return (
        <>
            <div className="w-full relative flex items-center">
                {/* Custom Navigation Buttons */}
                {/* <button
                    ref={prevRef}
                    className="mobile:invisible md:visible swiper-button-prev-custom absolute left-0 z-10 bg-custom-button bg-opacity-50 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-75 -translate-x-[140%]"
                >
                    <IoIosArrowBack />
                </button> */}

                <Swiper
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={true}

                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    pagination={{
                        el: paginationRef.current,
                        clickable: true,
                    }}
                    onSlideChange={(e: SwiperType) => {
                        setActiveIndex(e.realIndex);
                    }}
                    onSwiper={(swiper: SwiperType) => {
                        setTimeout(() => {
                            if (
                                swiper.params &&
                                swiper.params.navigation &&
                                typeof swiper.params.navigation !== 'boolean' &&
                                swiper.params.pagination &&
                                typeof swiper.params.pagination !== 'boolean'
                            ) {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.params.pagination.el = paginationRef.current;
                                swiper.navigation.destroy();
                                swiper.navigation.init();
                                swiper.navigation.update();
                                swiper.pagination.destroy();
                                swiper.pagination.init();
                                swiper.pagination.update();
                            }
                        });
                    }}
                >
                    {refCasesData.map((caseData, index) => (
                        <SwiperSlide key={index}>
                            <div className="flex mobile:flex-row flex-col items-stretch shadow-md border ">
                                {/* Left Section */}
                                <div className="flex flex-col w-full md:w-2/4 bg-custom-bg mobile:p-[5vw] md:p-[2vw] mobile:gap-12 md:gap-20 mobile:h-[80vh] ">
                                    <div className="flex flex-col gap-3 flex-grow mobile:mt-[1vh] md:mt-[5%]">
                                        <h1 className="mobile:text-[5vw] sm:text-[3vw] md:text-[1.10vw] text-">{caseData?.title}</h1>

                                        <span className="mobile:text-[4vw] sm:text-[2.5vw] md:text-[1.1vw] text-custom-gray text-balance leading-tight">
                                            {truncateText(caseData?.description, maxDescriptionLength)}
                                        </span>

                                        {/* Tags */}
                                        <div className="flex gap-2 mt-[5%]">
                                            {caseData?.tags?.map((tag) => (
                                                <span
                                                    key={tag}
                                                    className="border px-3 py-1 rounded-md bg-custom-white text-gray-600 text-sm shadow-md overflow-hidden whitespace-nowrap text-ellipsis max-w-[100px]"
                                                >
                                                    {tag}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Button */}
                                    <div className="flex gap-4 h-fit items-center">
                                        <Button
                                            className="h-14 lg:h-[10vh] px-2"
                                            variant="primary"
                                            icon={<FaArrowRight />}
                                            link={`/cases/${caseData.fields.slug}`}
                                            label="Läs mer om uppdraget"
                                        />
                                    </div>
                                </div>

                                {/* Right Section */}
                                <div
                                    className="mobile:hidden md:visible md:flex cursor-pointer relative w-full md:h-contain"

                                >
                                    <ContentfulImage
                                        image={caseData.image}
                                        alt={caseData.title}
                                        fill
                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                    />
                                </div>
                            </div>
                        </SwiperSlide>


                    ))}
                    {/* Pagination */}
                    <div className="flex justify-center mobile:m-[2vh]">
                        <div className="flex gap-1">
                            {refCasesData.map((_, index) => (
                                <span
                                    key={index}
                                    className={`w-2 h-2 mx-1 rounded-full inline-block ${activeIndex === index ? 'bg-custom-button-arrow' : 'bg-gray-300'
                                        }`}
                                ></span>
                            ))}
                        </div>
                    </div>
                </Swiper>

                {/* <button
                    ref={nextRef}
                    className="mobile:invisible md:visible swiper-button-next-custom absolute right-0 z-10 bg-custom-button bg-opacity-50 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-75 -translate-x-[-140%]"
                >
                    <IoIosArrowForward />
                </button> */}
            </div >
        </>
    );
};

export default CustomerCard;
