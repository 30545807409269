// components/AnimatedText.tsx
import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

interface AnimatedTextProps {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    href?: string;
    // Add any other props you might need
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ children, className, onClick, href }) => {
    const textRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const element = textRef.current;
        if (!element) return;

        // Define the hover-in animation
        const hoverIn = () => {
            gsap.to(element, {
                duration: 0.2,
                scale: 0.96,
                textDecoration: "underline",
                color: "#EE7101",
            });
        };

        // Define the hover-out animation
        const hoverOut = () => {
            gsap.to(element, {
                duration: 0.2,
                scale: 1,
                color: "#000000", // Original color (black)
                textDecoration: "none",
                ease: "power1.out",
            });
        };

        // Attach event listeners
        element.addEventListener("mouseenter", hoverIn);
        element.addEventListener("mouseleave", hoverOut);

        // Cleanup event listeners on unmount
        return () => {
            element.removeEventListener("mouseenter", hoverIn);
            element.removeEventListener("mouseleave", hoverOut);
        };
    }, []);

    if (href) {
        return (
            <span
                onClick={onClick}
                ref={textRef}
                className={`text-sm cursor-pointer ${className || ""}`}
                // Ensure accessibility
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                    if (e.key === "Enter" && onClick) onClick();
                }}
            >
                {children}
            </span>
        );
    }

    return (
        <span
            ref={textRef}
            onClick={onClick}
            className={`text-sm cursor-pointer ${className || ""}`}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
                if (e.key === "Enter" && onClick) onClick();
            }}
        >
            {children}
        </span>
    );
};

export default AnimatedText;
