import blogPostsData from ".data/contentful/blogPost/all.json";

const blogPosts = blogPostsData.filter(
  (person, index, self) =>
    index === self.findIndex((p) => p.sys.id === person.sys.id)
);

const blogPostFields = blogPosts.map((blogPost) => {
  return {
    title: blogPost.fields.title,
    description: blogPost.fields.description,
    tags: blogPost.fields.tags,
    image: blogPost.fields.image,
    authors: blogPost.fields.authors,
    body: blogPost.fields.body,
    date: blogPost.fields.publishDate,
    slug: blogPost.fields.slug,
    sys: blogPost.sys,
  };
});

export default blogPostFields;
